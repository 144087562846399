import React, { useContext, useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { PrismicRichText } from "@prismicio/react";
import styled from "styled-components";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

// Context
import { PageSetup } from "../components/context/page-context";

// Components
import { SinglePrismicProduct } from "../components/product/single-product";
import { ArchiveEvents } from "../components/events/archive-events";

// Containers
import { AspectRatioImageContainer } from "../components/containers/aspect-ratio-image-container";

// SEO
import { PageSeo } from "../components/seo/page-seo";

const Page = styled.div`
  padding: 51px 0 0 0;

  @media (max-width: 960px) {
    padding: 30px 0 0 0;
  }

  & .breadcrumbs {
    padding: 0 50px;
    margin: 0 0 10px 0;

    & p {
      font-size: 12px;
      line-height: 28px;

      color: #0b646f;

      margin: 0;
    }

    @media (max-width: 768px) {
      padding: 0 10px;
      margin: 0;

      & p {
        font-size: 8px;
        line-height: 28px;
      }
    }
  }

  & .current-events-container {
    & .content-container {
      padding: 0 50px;

      @media (max-width: 960px) {
        padding: 0 30px;
      }

      @media (max-width: 768px) {
        padding: 0 20px;
      }
    }

    & .single-current-event-container {
      margin: 0 0 70px 0;

      @media (max-width: 768px) {
        margin: 0 0 45px 0;
      }

      &:last-of-type {
        margin: 0 0 70px 0;

        @media (max-width: 768px) {
          margin: 0 0 30px 0;
        }
      }

      & .banner-image-container {
        margin: 0 0 50px 0;

        // & .gatsby-image-wrapper {
        //   display: block;
        //   width: 100%;
        // }

        & .gatsby-image-wrapper-constrained {
          width: 100%;
        }

        @media (max-width: 768px) {
          margin: 0 0 15px 0;
        }
      }

      & .text-container {
        /* max-width: 875px; */
        max-width: 785px;
        margin: 0 auto;

        & h1,
        & h2 {
          font-size: 18px;
          line-height: 26px;
          /* font-size: 22px;
          line-height: 32px; */

          text-align: center;

          color: #0b646f;

          @media (max-width: 768px) {
            font-size: 14px;
            line-height: 20px;
          }
        }

        & p {
          font-size: 16px;
          line-height: 23px;

          /* font-size: 14px;
          line-height: 21px; */

          color: #0b646f;

          &:first-of-type {
            margin-top: 0;
          }

          &:last-of-type {
            margin-bottom: 0;
          }

          @media (max-width: 768px) {
            font-size: 12px;
            line-height: 17px;
          }
        }

        & .text {
          margin: 40px 0 50px 0;

          @media (max-width: 768px) {
            margin: 20px 0;
          }

          & p {
            &.event-link {
              & a {
                display: block;

                color: #0b646f;
                font-size: 10px;
                line-height: 32px;
                text-align: center;
                letter-spacing: 0.02em;

                min-width: 120px;
                width: fit-content;
                height: 30px;

                padding: 0 20px;

                background: #f5eadf;

                font-family: "HouseofVoltaireWeb-Display", "Helvetica Neue",
                  "Lucida Grande", sans-serif;

                @media (hover: hover) and (pointer: fine) {
                  transition: 250ms color ease, 250ms background-color ease;

                  &:hover {
                    color: #fff;
                    background-color: #0b646f;
                  }
                }

                @media (max-width: 768px) {
                  margin: 0 auto;
                }
              }
            }
          }
        }
      }
    }

    & .products-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 50px;
      grid-row-gap: 100px;

      padding: 0 50px;

      @media (max-width: 1000px) {
        grid-template-columns: 1fr 1fr;

        & .single-product {
          &:nth-of-type(3) {
            display: none;
          }
        }
      }

      @media (max-width: 960px) {
        padding: 0 30px;
      }

      @media (max-width: 768px) {
        display: block;
        grid-template-columns: unset;
        grid-column-gap: unset;
        grid-row-gap: unset;
      }
    }
  }

  & .archive-events-container {
    background-color: #f5eadf;

    & .text-container {
      /* max-width: 975px; */

      max-width: 885px;
      margin: 0 auto;
      padding: 50px 50px 100px 50px;

      & h2,
      & p {
        color: #0b646f;
      }

      & p {
        font-size: 16px;
        line-height: 23px;

        @media (max-width: 768px) {
          font-size: 12px;
          line-height: 17px;
        }
      }

      & h2 {
        text-align: center;
        line-height: 36px;

        margin: 0 0 30px 0;

        @media (max-width: 768px) {
          font-size: 14px;
          line-height: 20px;
        }
      }

      & p {
        &:first-of-type {
          margin-top: 0;
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }

      @media (max-width: 960px) {
        padding: 30px 30px 100px 30px;
      }

      @media (max-width: 768px) {
        padding: 25px 20px 20px 20px;
      }
    }
  }
`;

const Events = ({ data }) => {
  const [pageSetup, setPageSetup] = useContext(PageSetup);

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 769px)");

  useEffect(() => {
    setPageSetup({
      pageTitle: ``,
      pageColor: `#DDE1D4`,
      pageType: `events`,
      filterArtists: [],
      selectedArtists: [],
      sortOption: null,
      priceOption: null,
      artistOption: [],
      applyFilter: false,
    });
  }, []);

  const content = data.prismicEvents.data.body.map((content, index) => {
    if (content.slice_type === `event`) {
      const products = content.items
        .filter(product => product.product !== null)
        .filter((product, index) => {
          if (index <= 2) {
            return product;
          }
        })
        .map((product, index) => (
          <SinglePrismicProduct
            product={product}
            index={index}
            key={`single_product_${index}_${product.id}`}
            isDesktop={isDesktop}
          />
        ));

      return (
        <div
          className="single-current-event-container"
          key={`single_current_event_container_${index}_${content.id}`}
        >
          <div className="banner-image-container">
            {isDesktop ? (
              <AspectRatioImageContainer image={null} padding={35.748}>
                <GatsbyImage
                  image={content.primary.banner_image.gatsbyImageData}
                  alt={
                    content.primary.banner_image.alt !== null
                      ? content.primary.banner_image.alt
                      : ""
                  }
                  draggable="false"
                  loading="lazy"
                  loading="lazy"
                />
              </AspectRatioImageContainer>
            ) : (
              <GatsbyImage
                image={content.primary.mobileImage.gatsbyImageData}
                alt={
                  content.primary.mobileImage.alt !== null
                    ? content.primary.mobileImage.alt
                    : ""
                }
                draggable="false"
                loading="lazy"
                loading="lazy"
              />
            )}
          </div>

          <div className="content-container">
            <div className="text-container">
              <div className="title">
                <PrismicRichText field={content.primary.event_title.richText} />
              </div>

              <div className="dates">
                <PrismicRichText field={content.primary.event_dates.richText} />
              </div>

              <div className="text news-gothic">
                <PrismicRichText field={content.primary.event_text.richText} />

                <PrismicRichText
                  field={content.primary.event_link.richText}
                  components={{
                    paragraph: ({ children }) => (
                      <p
                        className="event-link uppercase"
                        key={`single_product_row_p_${index}`}
                      >
                        {children}
                      </p>
                    ),
                  }}
                />
              </div>
            </div>

            {products.length >= 1 && (
              <div className="products-container">{products}</div>
            )}
          </div>
        </div>
      );
    }
  });

  return (
    <>
      <PageSeo
        seoTitle={data.prismicEvents.data.seo_title}
        seoText={data.prismicEvents.data.seo_description}
        seoImage={data.prismicEvents.data.seo_image}
      />

      <Page>
        {!isDesktop && (
          <section className="breadcrumbs">
            <p>
              <Link to={`/`}>HOME</Link> → Events
            </p>
          </section>
        )}

        <section className="current-events-container">{content}</section>

        <section className="archive-events-container">
          <div className="text-container">
            <div className="title">
              <PrismicRichText
                field={data.prismicEvents.data.archive_title.richText}
              />
            </div>

            <div className="text news-gothic">
              <PrismicRichText
                field={data.prismicEvents.data.archive_text.richText}
              />
            </div>
          </div>

          <div className="events">
            <ArchiveEvents />
          </div>
        </section>
      </Page>
    </>
  );
};

export default withPrismicPreview(Events);

export const query = graphql`
  {
    prismicEvents {
      _previewable
      data {
        title {
          text
        }
        archive_title {
          richText
        }
        archive_text {
          richText
        }
        seo_title
        seo_description
        seo_image {
          url
        }
        body {
          ... on PrismicEventsDataBodyEvent {
            id
            slice_type
            primary {
              banner_image {
                gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 2.8)
                alt
              }
              mobileImage: banner_image {
                gatsbyImageData(aspectRatio: 1.3)
                alt
              }
              event_title {
                richText
              }
              event_text {
                richText
              }
              event_dates {
                richText
              }
              event_link {
                richText
              }
            }
            items {
              product {
                title
                handle
                id
                status
                admin_graphql_api_id
                image {
                  src
                }
                variants {
                  price
                  admin_graphql_api_id
                }
              }
            }
          }
        }
      }
    }
  }
`;

import React, { useContext, useState, useEffect } from "react";

// Context
import { StoreContext } from "../context/store-context";

// Components
import { AddedToCartPopup } from "./added-to-cart-popup";

export const AddToCart = ({
  variantId,
  quantity,
  available,
  product,
  price,
  artistName,
  hasVariantChanged,
  isPreview,
  ...props
}) => {
  const { addVariantToCart, loading } = useContext(StoreContext);
  const [addedToCart, setAddedToCart] = useState(false);

  const addToCart = e => {
    e.preventDefault();
    addVariantToCart(variantId, quantity);
    setAddedToCart(true);
  };

  useEffect(() => {
    if (addedToCart) {
      const closeAddToCartPopup = setTimeout(() => {
        setAddedToCart(false);
      }, 5000);
      return () => clearTimeout(closeAddToCartPopup);
    }
  }, [addedToCart]);

  return (
    <>
      <div className="product-buttons">
        <button
          disabled={!available || loading || isPreview}
          onClick={e => addToCart(e)}
          {...props}
          className="uppercase"
        >
          {available ? (addedToCart ? "Added!" : "Add to Bag") : "Out of Stock"}
        </button>
      </div>

      {addedToCart && (
        <AddedToCartPopup
          product={product}
          price={price}
          artistName={artistName}
        />
      )}
    </>
  );
};

import React, { useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { useAsyncFn } from "react-use";

// Components
import { Header } from "./header";
import { Footer } from "./footer";
import { Cookies } from "../cookies/cookies";

// Hooks
import { useMediaQuery } from "../hooks/useMediaQuery";

// Context
import { useDraftProductsContext } from "../context/draft-products";

const Main = styled.main`
  min-height: calc(${props => props.height}px - 50px - 40px);
`;

export const App = ({
  location,
  children,
  pageSetup,
  setPageSetup,
  height,
}) => {
  // Media Query
  let isDesktop = useMediaQuery("(min-width: 961px)");

  const {
    draftProducts,
    setSessionStorageDraftProducts,
  } = useDraftProductsContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `/.netlify/functions/get-all-draft-products`,
          {
            method: "POST",
            headers: new Headers({
              "Content-Type": "application/json",
            }),
          }
        );

        const result = await response.json();
        setSessionStorageDraftProducts(result.products.products.nodes); // Assuming setSessionStorageDraftProducts is defined
        // You might want to perform further actions with the result here
      } catch (error) {
        // Handle any errors that might occur during the fetch
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call the fetchData function
  }, []); // Empty dependency array ensures the effect runs only once

  return (
    <>
      <Helmet
        meta={[
          {
            name: "viewport",
            content:
              "width=device-width, user-scalable=no, initial-scale=1, maximum-scale=1",
          },
          {
            name: "facebook-domain-verification",
            content: "qduqjt2j96c918931sthmcr3sqh0m0",
          },
          {
            name: "google-site-verification",
            content: "4VqnUHS8jF8V-dRtpCSHi-0uj2OqRSpotnKa32OMw2E",
            // content: "KWbEapy1-KfpJ3k9syT3ttNI7rEa7WIopq79YPLU6Wg",
          },
        ]}
      />
      {pageSetup.pageType !== `delivery` && (
        <Header
          location={location}
          pageSetup={pageSetup}
          setPageSetup={setPageSetup}
          isDesktop={isDesktop}
        />
      )}

      <Main height={height}>{children}</Main>

      {pageSetup.pageType !== `delivery` && isDesktop && (
        <Footer location={location} pageSetup={pageSetup} />
      )}

      <Cookies location={location} />
    </>
  );
};

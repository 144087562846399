import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { PrismicRichText } from "@prismicio/react";
import styled from "styled-components";

const ArchiveEventsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 50px;
  grid-row-gap: 130px;

  padding: 0 50px 100px 50px;

  @media (max-width: 960px) {
    padding: 0 30px 100px 30px;
  }

  & .single-archive-event {
    & .image-container {
      width: 100%;
      position: relative;

      & .thumbnail-images {
        position: relative;

        & img {
          object-fit: cover;
          width: 100%;
          height: 100%;

          &:first-of-type {
            position: relative;
            z-index: 1;
          }

          &:last-of-type {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            z-index: 0;
          }
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            & img {
              &:first-of-type {
                z-index: 0;
              }

              &:last-of-type {
                z-index: 1;
              }
            }
          }
        }
      }
    }

    & .title-container {
      margin: 21px 0 0 0;

      & h1 {
        font-size: 12px;
        line-height: 17px;

        text-align: center;

        color: #0b646f;
      }
    }
  }

  @media (max-width: 768px) {
    display: block;
    grid-template-columns: unset;
    grid-column-gap: unset;
    grid-row-gap: unset;

    padding: 0 20px 20px 20px;

    & .single-archive-event {
      margin: 0 0 35px 0;

      & .title-container {
        margin: 10px 0 0 0;
      }
    }
  }
`;

export const ArchiveEvents = () => {
  const data = useStaticQuery(graphql`
    {
      allPrismicEvent(sort: { fields: data___archive_date, order: DESC }) {
        nodes {
          id
          url
          data {
            title {
              richText
            }
            thumbnail {
              alt
              fluid(imgixParams: { ar: "1.4", fit: "crop" }) {
                srcSet
                src
              }
            }
            rollover_thumbnail {
              alt
              fluid(imgixParams: { ar: "1.4", fit: "crop" }) {
                srcSet
                src
              }
            }
          }
        }
      }
    }
  `);
  const content = data.allPrismicEvent.nodes.map((event, index) => (
    <div
      className="single-archive-event"
      key={`single_archive_event_${event.id}`}
    >
      <Link to={event.url}>
        <div className="image-container">
          <div className="thumbnail-images">
            <img
              srcSet={event.data.thumbnail.fluid.srcSet}
              src={event.data.thumbnail.fluid.src}
              alt={event.data.thumbnail.alt}
              loading="lazy"
            />
            <img
              srcSet={event.data.rollover_thumbnail.fluid.srcSet}
              src={event.data.rollover_thumbnail.fluid.src}
              alt={event.data.rollover_thumbnail.alt}
              loading="lazy"
            />
          </div>
        </div>

        <div className="title-container">
          <PrismicRichText field={event.data.title.richText} />
        </div>
      </Link>
    </div>
  ));

  return <ArchiveEventsContainer>{content}</ArchiveEventsContainer>;
};

import React, { useContext, useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Context
import { PageSetup } from "../components/context/page-context";
import { useDraftProductsContext } from "../components/context/draft-products";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

// Components
import { SingleProduct } from "../components/product/single-product";
import { MobileFilters } from "../components/filter/mobile-filters";

// Utils
import { getMetafield } from "../components/utils/get-metafield";
import { dynamicSort } from "../components/utils/dynamic-sort";

// SEO
import { PageSeo } from "../components/seo/page-seo";

const Page = styled.div`
  margin: 50px 0;

  @media (max-width: 960px) {
    margin: 30px 0 75px 0;
  }

  & .banner-container {
    overflow: hidden;
    margin: 0 0 20px 0;

    & .banner {
      position: relative;
      width: 100%;
      height: 100%;

      & .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
      }

      & h1 {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        padding: 0 10px;

        z-index: 10;

        /* font-size: 48px;
        line-height: 65px; */

        font-size: 38px;
        line-height: 52px;

        text-transform: uppercase;
        text-align: center;

        color: ${props => props.bannerTitleColor};

        @media (max-width: 768px) {
          font-size: 22px;
          line-height: 20px;
        }
      }
    }
  }

  & .breadcrumbs {
    padding: 0 50px;
    margin: 0 0 10px 0;

    & p {
      font-size: 12px;
      line-height: 28px;

      color: #0b646f;

      margin: 0;
    }

    @media (max-width: 960px) {
      padding: 0 30px;
    }

    @media (max-width: 768px) {
      padding: 0 10px;
      margin: 0;

      & p {
        font-size: 8px;
        line-height: 28px;
      }
    }
  }

  & .products-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 50px;
    grid-row-gap: 100px;

    padding: 0 50px;

    @media (max-width: 1000px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 960px) {
      padding: 0 30px;
    }

    @media (max-width: 768px) {
      display: block;
      grid-template-columns: unset;
      grid-column-gap: unset;
      grid-row-gap: unset;
    }
  }

  & .empty-products-container {
    padding: 50px;

    @media (max-width: 960px) {
      padding: 0 30px;
    }

    & h1 {
      font-size: 22px;
      line-height: 32px;
      text-align: center;
      color: #0b646f;
    }
  }
`;

const Shop = ({ data, location }) => {
  const [pageSetup, setPageSetup] = useContext(PageSetup);

  // Draft Products
  const { draftProducts } = useDraftProductsContext();

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 769px)");

  const allArtists = Array.from(
    new Set(
      data.allShopifyProduct.nodes
        .map(product => getMetafield(product, "artist_name"))
        .filter(artist => artist !== null)
        .map(artist => artist.trim())
    )
  ).sort();

  useEffect(() => {
    setPageSetup({
      pageTitle: ``,
      pageColor: `#fff`,
      pageType: `shop`,
      filterArtists: allArtists,
      selectedArtists: [],
      sortOption: `newest`,
      priceOption: null,
      artistOption: [],
      applyFilter: false,
    });
  }, []);

  const filteredProducts = data.allShopifyProduct.nodes
    .filter(product => draftProducts.indexOf(product.shopifyId) === -1)
    .filter(product => {
      if (pageSetup.priceOption !== null && pageSetup.applyFilter === true) {
        if (pageSetup.priceOption.direction === `greater`) {
          if (
            product.priceRangeV2.minVariantPrice.amount >=
            pageSetup.priceOption.price
          ) {
            return product;
          }
        } else {
          if (
            product.priceRangeV2.minVariantPrice.amount <=
            pageSetup.priceOption.price
          ) {
            return product;
          }
        }
      } else {
        return product;
      }
    })
    .filter(product => {
      if (
        pageSetup.artistOption.length >= 1 &&
        pageSetup.applyFilter === true
      ) {
        if (getMetafield(product, "artist_name") !== null) {
          if (
            pageSetup.artistOption.includes(
              getMetafield(product, "artist_name").trim()
            )
          ) {
            return product;
          }
        }
      } else {
        return product;
      }
    });

  const content = filteredProducts
    .sort(dynamicSort(pageSetup.sortOption))
    .map((product, index) => (
      <SingleProduct
        product={product}
        index={index}
        key={`single_product_${index}_${product.id}`}
        isDesktop={isDesktop}
      />
    ));

  return (
    <>
      <PageSeo
        seoTitle={data.prismicShop.data.seo_title}
        seoText={data.prismicShop.data.seo_description}
        seoImage={data.prismicShop.data.seo_image}
      />

      <Page bannerTitleColor={data.prismicShop.data.banner_title_color}>
        {!isDesktop && (
          <section className="breadcrumbs">
            <p>
              <Link to={`/`}>HOME</Link> → <Link to={`/shop/`}>Shop</Link> → ALL
              PRODUCTS
            </p>
          </section>
        )}

        <section className="banner-container">
          <div className="banner">
            {isDesktop ? (
              <GatsbyImage
                image={data.prismicShop.data.banner_image.gatsbyImageData}
                alt={
                  data.prismicShop.data.banner_image.alt !== null
                    ? data.prismicShop.data.banner_image.alt
                    : ""
                }
                draggable="false"
                loading="lazy"
              />
            ) : (
              <GatsbyImage
                image={data.prismicShop.data.mobileImage.gatsbyImageData}
                alt={
                  data.prismicShop.data.mobileImage.alt !== null
                    ? data.prismicShop.data.mobileImage.alt
                    : ""
                }
                draggable="false"
                loading="lazy"
              />
            )}

            <h1>{data.prismicShop.data.banner_title.text}</h1>
          </div>
        </section>

        {isDesktop && (
          <section className="breadcrumbs">
            <p>
              <Link to={`/`}>HOME</Link> → <Link to={`/shop/`}>Shop</Link> → ALL
              PRODUCTS
            </p>
          </section>
        )}

        {content.length >= 1 ? (
          <section className="products-container">{content}</section>
        ) : (
          <section className="empty-products-container">
            <h1>No results</h1>
          </section>
        )}

        {!isDesktop && (
          <MobileFilters
            pageSetup={pageSetup}
            setPageSetup={setPageSetup}
            isDesktop={isDesktop}
            location={location}
          />
        )}
      </Page>
    </>
  );
};

export default withPrismicPreview(Shop);

export const query = graphql`
  {
    prismicShop {
      _previewable
      data {
        banner_title {
          text
        }
        banner_title_color
        banner_image {
          alt
          gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 2.8)
        }
        mobileImage: banner_image {
          gatsbyImageData(aspectRatio: 1.1)
          alt
        }
        seo_title
        seo_description
        seo_image {
          url
        }
      }
    }
    allShopifyProduct(
      sort: { order: [DESC], fields: [updatedAt] }
      filter: { status: { eq: ACTIVE } }
    ) {
      nodes {
        id
        updatedAt
        totalInventory
        featuredImage {
          src
          width
          height
          altText
          gatsbyImageData(width: 700)
        }
        mobileImage: featuredImage {
          gatsbyImageData(width: 300)
        }
        title
        handle
        priceRangeV2 {
          minVariantPrice {
            amount
            currencyCode
          }
        }
        storefrontId
        shopifyId
        variants {
          id
          title
          price
          availableForSale
          shopifyId
          storefrontId
          inventoryQuantity
          compareAtPrice
          selectedOptions {
            name
            value
          }
        }
        metafields {
          key
          value
        }
      }
    }
  }
`;

import React, { useContext, useCallback, useState, useEffect } from "react";
import { Link } from "gatsby";
import { useAsyncFn } from "react-use";

// Context
import { StoreContext } from "../context/store-context";

// Icons
import { ArrowRight, ArrowLeft } from "../icons/cart-icons";

// Utils
import { formatPrice } from "../utils/format-price";
import debounce from "lodash.debounce";

export const LineItem = ({ item }) => {
  const { removeLineItem, checkout, updateLineItem, loading } = useContext(
    StoreContext
  );
  const [quantity, setQuantity] = useState(item.quantity);

  const variantImage = {
    ...item.variant.image,
    originalSrc: item.variant.image.src,
  };
  const price = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount)
  );

  const subtotal = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount) * quantity
  );

  const handleRemove = () => {
    removeLineItem(checkout.id, item.id);
  };

  const uli = debounce(
    value => updateLineItem(checkout.id, item.id, value),
    300
  );
  // eslint-disable-next-line
  const debouncedUli = useCallback(value => uli(value), []);

  const handleQuantityChange = value => {
    if (value !== "" && Number(value) < 1) {
      return;
    }
    setQuantity(value);
    if (Number(value) >= 1) {
      debouncedUli(value);
    }
  };

  function doIncrement() {
    handleQuantityChange(Number(quantity || 0) + 1);
  }

  function doDecrement() {
    handleQuantityChange(Number(quantity || 0) - 1);
  }

  const [productArtist, getProductArtist] = useAsyncFn(async id => {
    const response = await fetch(`/.netlify/functions/get-product-artist`, {
      method: "POST",
      body: JSON.stringify({
        id: id,
      }),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    });

    const result = await response.json();
    return result;
  }, []);

  useEffect(() => {
    if (item.variant.id !== null) {
      getProductArtist(item.variant.id);
    }
  }, [item]);

  const [productArtistName, setProductArtistName] = useState(``);

  useEffect(() => {
    if (productArtist.loading === false && productArtist.value !== undefined) {
      if (
        productArtist.value.metafields !== null &&
        productArtist.value.metafields !== undefined
      ) {
        if (productArtist.value.metafields.length >= 1) {
          const artistName = productArtist.value.metafields.filter(
            field => field.key === `artist_name`
          );

          setProductArtistName(artistName[0].value);
        }
      }
    }
  }, [productArtist]);

  return (
    <div className="row item">
      <div className="item-image">
        <img src={item.variant.image.src} alt={item.title} loading="lazy" />
      </div>

      <div className="item-details">
        <h1 className="item-title">
          <Link to={`/products/${item.variant.product.handle}/`}>
            {productArtistName}
            <br />
            {item.title}
          </Link>
        </h1>
        <p className="item-price">{subtotal}</p>
        {item.variant.selectedOptions[0].name !== "Title" && (
          <p className="item-style">
            {item.variant.selectedOptions[0].name}:{" "}
            {item.variant.selectedOptions[0].value}
          </p>
        )}

        <div className="item-quantity">
          <div className="quantity-title">
            <p>Quantity</p>
          </div>

          <div className="quantity-buttons-container">
            <button onClick={() => doDecrement()}>
              <ArrowLeft />
            </button>
            <span className="quantity">{item.quantity}</span>
            <button onClick={() => doIncrement()}>
              <ArrowRight />
            </button>
          </div>
        </div>

        <div className="item-remove">
          <button onClick={handleRemove}>Remove</button>
        </div>
      </div>
    </div>
  );
};

import React, { createContext, useContext, useState } from "react";

const DraftProductsContext = createContext();

export function useDraftProductsContext() {
  return useContext(DraftProductsContext);
}

export function DraftProductsProvider({ children }) {
  const [draftProducts, setDraftProducts] = useState([]);

  const setSessionStorageDraftProducts = data => {
    // Implement your sessionStorage logic here to store the data
    // For example:
    // sessionStorage.setItem('draftProducts', JSON.stringify(data));
    setDraftProducts(data);
  };

  return (
    <DraftProductsContext.Provider
      value={{ draftProducts, setSessionStorageDraftProducts }}
    >
      {children}
    </DraftProductsContext.Provider>
  );
}
